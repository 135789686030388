nav{
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: hsla(0, 0%, 100%, 0.4);
  backdrop-filter: blur(2px);
  border: 3px solid white;
  width: 100%;
}

nav ul li{
  text-align: center;
  width: 115px;
  border-left: 1px solid white;
  color: black;
}
nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  user-select: none;
}
nav ul li:hover{
  cursor: pointer;
  border: 2px solid black;
  border-radius: 5px;
}
nav ul a:first-child {
  width: 110px;
  margin-right: auto;
}
#gitHub-redirect{
  color: white;
}