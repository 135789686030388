body {
  background-color: hsl(0, 0%, 20%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 30%);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 35%);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: #000;
}