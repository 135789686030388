main {
    color: black;
}

/* Popup Container */
.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 10px;
    width: 310px;
    height: 300px;
    border: 4px dashed hsla(0, 0%, 100%, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1001;
}

.popup-container p,
.popup-container h1,
.popup-container div {
    background-color: hsla(0, 0%, 100%, 0.3);
    border-radius: 5px;
    padding: 5px;
}

.blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.mobileVisitBtn {
    width: 150px;
    border-radius: 5px;
    border-style: groove;
    font-size: 15px;
    background-color: hsla(0, 0%, 100%, 0.3);
    transition: border-radius 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.mobileVisitBtn:hover {
    cursor: pointer;
    border-radius: 15px;
}

.mobileVisitBtn:active {
    color: hsl(0, 0%, 40%);
    border-style: dashed;
    opacity: 0.5;
}

/* How To Section */
.howTo {
    margin-top: 10px;
    line-height: 1.5; /* Adjusted for readability */
}

.howTo p {
    font-family: 'Comic Neue', 'Luckiest Guy', cursive;
}

u {
    background-color: hsl(0, 0%, 13%);
    padding: 2px;
}

.howToPlay {
    background-color: hsl(0, 0%, 10%);
}

/* Exit Settings Button */
#exit-settings {
    position: absolute;
    left: 89.5%;
    top: 8%;
    padding: 7px 11px;
    border-radius: 50%;
    background-color: hsl(0, 0%, 80%);
    border: 1px solid black;
    color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
}

#exit-settings:active {
    background-color: hsl(284, 35%, 15%);
}

#exit-settings:hover {
    color: hsl(0, 0%, 70%);
}

/* Responsive Design */
@media only screen and (max-width: 600px) {
    body .projects--category h2{
        padding: 20px 0px 20px 0px;
    }
    body ul li {
        font-size: 13px;
        width: 84px;
    }

    body .video-background {
        object-fit: cover;
        height: 100vh;
        width: 100vw;
    }

    body #welcome {
        text-align: center;
        margin: 40px 0;
    }
}

@media only screen and (max-width: 800px) {
    #video {
        margin: 20px 10px;
    }

    .moreAbout {
        flex-direction: column;
        align-items: center;
    }

    .litteTalk,
    .vidContainer {
        width: 100%;
        margin: 0;
    }

    .litteTalk {
        text-align: center;
        margin-bottom: 20px;
        line-height: normal;
    }

    .vidContainer {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 301px) and (max-width: 600px) {
    #me {
        font-size: 20px;
    }

    .right-side span {
        font-size: 12px;
    }

    ul li {
        width: 102px;
    }

    .Recources img {
        width: 35px;
    }
}

@media (min-width: 601px) and (max-width: 1100px) {
    #me {
        font-size: 22px;
    }
}