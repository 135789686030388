
.projects--category h2{
  width: 400px;
  margin: 0 auto;
  padding: 15px 0px 15px 0px;
}
#more{
    border: 3px dotted white;
    border-top: none;
    border-bottom: none;
    border-radius: 50%;
    width: 80%;
    max-width: 500px;
}
.courceInfo{
  position: absolute;
  width: 20px;
  top: 10px;
  right: 12px;
  padding: 1px 5px 1px 5px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.2);

  border: 1px solid white;
  border-radius: 50%;

  transition: border-color 0.3s ease, color 0.3s ease;
}
.courceInfo:hover{
  cursor:pointer;
  user-select: none;

  color: black;
  border-color: black;
}
.info{
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0px 15px 5px 15px;

  animation: fade 0.7s 1;
}

@keyframes fade{
  50%{opacity: 0.1;}
  100%{opacity: 1 ;}
} 
