
.hello{
  display: flex;
  justify-content: center;
  align-items: center;   
  height: 90vh;         
  transition: opacity 0.5s ease;
  font-size: 30px;
  line-height: 2vh;
  z-index: 1002;

  animation: shade 3s 1;
}

.hello h2{
text-align: center;
}

.hello h2:first-child{
font-size: 60px;
}

@keyframes shade{
  0%{opacity: 0;}
  100%{opacity: 1;}
} 

#continueMsg{
margin-top: 60px;
opacity: 0.5;
font-size: 20px;

animation: shadeInf 2s infinite;
}

@keyframes shadeInf{
0%{opacity: 0.3;}
50%{opacity: 0.8;}
100%{opacity: 0.3;}
} 